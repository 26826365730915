<template>
  <div>
    <button @click="handleShare">Share</button>
  </div>
</template>

<script>
import { flutterShowToast } from "@/utils/appChannel";
export default {
  setup() {
    const handleShare = () => {
      let param = {
        //  shareType:'shareH5PageToDialog',
        shareType: "shareToMiniProgram",
        //  url:'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8be5e70285d97c03&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&redirect_uri=https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8be5e70285d97c03&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&redirect_uri=http://tangying.ujiaku.com/shareTest',
        urlEntity: {
          content: "http://tangying.ujiaku.com/about",
        },
        title: "测试分享",
        desc: "吊炸天，啊哈哈哈哈哈",
      };
      flutterShowToast(param);
    };
    return { handleShare };
  },
};
</script>

<style lang="scss" scoped></style>
