import { onMounted, onUnmounted } from 'vue'
import $ from 'jquery';
// import { useRoute } from 'vue-router'
// import {useStore} from 'vuex'

/**
 * shareId:分享视频文章或案例的ID
 * clueSource:线索来源 1获客文章 2获客视频 3获客海报 4其他
 * openId:微信授权拿到的openId
 * title：标题
 * createId：是谁的线索
 * merchantId:对应商家
 * consultId：留咨的id
 * @param {*} param0 
 */
// { shareId, clueSource, openId, title, createId, merchantId, consultId }
export function useBrowseCount(param) {
    // const route = useRoute()
    // alert(route.query)
    // let miniProgramPhone = route.query.state
    //     ? route.query.state
    //     : route.query.miniProgramPhone;
    // if(!miniProgramPhone){
    //     miniProgramPhone = null
    // }  
    console.log('==========', param)
    let internal = null;
    let seconds = 0;
    const handleAddClue = () => {
        if (seconds > 0) {
            let params = {
                shareId: param.shareId,
                clueSource: param.clueSource,
                browseSecond: seconds,
                beSharePhone: param.miniProgramPhone,
                openId: param.openId,
                title: param.title,
                createId: param.createId,
                merchantId: param.merchantId,
                consultId: param.consultId,
            };

            // let blob = new Blob([JSON.stringify(params)], {
            //     type: "application/json",
            //   });
            //   console.log(blob);
            //   navigator.sendBeacon(
            //     `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
            //     blob
            //   );
            $.ajax({
                async: false,
                url: `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
                type: "post",
                contentType: "application/json;charset=UTF-8",
                data: JSON.stringify(params),
            });
            clearInterval(internal);
            seconds = 0;
        }

    };
    const handleVisibilityStateChange = () => {
        if (document.visibilityState === "hidden") {
            handleAddClue();
            clearInterval(internal);
            seconds = 0;

        }
    };
    const handleWindowHide = () => {
        if (seconds > 0) {
            let params = {
                shareId: param.shareId,
                clueSource: param.clueSource,
                browseSecond: seconds,
                beSharePhone: param.miniProgramPhone,
                openId: param.openId,
                title: param.title,
                createId: param.createId,
                merchantId: param.merchantId,
                consultId: param.consultId,
            };
            let blob = new Blob([JSON.stringify(params)], {
                type: "application/json",
            });
            seconds = 0;
            navigator.sendBeacon(
                `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
                blob
            );
            clearInterval(internal);
            seconds = 0;
        }
    }
    onMounted(() => {
        console.log('-----------sdkjsald')
        internal = setInterval(() => {
            seconds++;
        }, 1000);
        document.addEventListener(
            "visibilitychange",
            handleVisibilityStateChange, false
        );
        window.addEventListener('pagehide', handleWindowHide, false);
    });
    onUnmounted(() => {
        clearInterval(internal);
        handleAddClue();
        document.removeEventListener(
            "visibilitychange",
            handleVisibilityStateChange
        );
        window.removeEventListener('pagehide', handleWindowHide, false);
    });
}