<template>
  <div>
    <div @click="tyest">嗲你的</div>
    hahahah======
    <!-- <img :src="userInfo.headImgUrl" alt="" />
    <p>{{ userInfo.sex }}</p>
    <p>{{ userInfo.nickname }}</p>
    <p>{{ userInfo.country }}</p>
    <p>{{ userInfo.province }}</p>
    <p>{{ userInfo.city }}</p> -->
  </div>
</template>

<script>
import $ from "jquery";
import { ref, onMounted, onUnmounted } from "vue";
import { useWxAuthorization } from "@/hooks/wxAuthorization";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
// import { addClue } from "@/api/user/clue";
export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    let internal = null;
    let userInfo = ref({});
    const getInfo = async () => {
      console.log(route);
      userInfo.value = await useWxAuthorization();
    };

    getInfo();
    let seconds = 0;
    const handleAddclue = () => {
      debugger;
      // if (document.visibilityState === "hidden") {
      let tokenParam = {
        clientId: "system",
        clientSecret: "system",
        scope: "app",
        grantType: "refresh_token",
        refreshToken: store.getters.refresh_token,
      };
      $.ajax({
        url: `${process.env.VUE_APP_BASE_API}/user/oauth/user/token`,
        type: "post",
        async: false,
        data: JSON.stringify(tokenParam),
        dataType: "json",
        contentType: "application/json;charset=utf-8",
        success: (data) => {
          console.log(data);
          store.commit("SET_ACCESS_TOKEN", data.response.access_token);
          store.commit("SET_REFRESH_TOKEN", data.response.refresh_token);
        },
      });
      clearInterval(internal);
      let clueParam = {
        shareId: 11,
        clueSource: 1,
        browseSecond: seconds,
        sharePhone: "MTg1ODE1OTQ1ODk=",
        beSharePhone: "MTg1ODE1OTQ1ODk=",
        country: userInfo.value.country,
        province: userInfo.value.province,
        city: userInfo.value.city,
        name: userInfo.value.nickname,
      };
      $.ajax({
        url: `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
        type: "post",
        async: false,
        beforeSend: function(request) {
          request.setRequestHeader(
            "Authorization",
            `Bearer ${store.getters.access_token}`
          );
        },
        data: JSON.stringify(clueParam),
        dataType: "json",
        contentType: "application/json;charset=utf-8",
        success: () => {
          alert("成功");
        },
      });
      // await store.dispatch("refreshToken");
      // console.log("onBeforeUnmount===========", seconds);
      // await addClue(param);
    };
    // };
    const test = () => {
      let clueParam = {
        shareId: 11,
        clueSource: 1,
        browseSecond: seconds,
        sharePhone: "MTg1ODE1OTQ1ODk=",
        beSharePhone: "MTg1ODE1OTQ1ODk=",
      };
      let blob = new Blob([JSON.stringify(clueParam)], {
        Authorization: `Bearer ${store.getters.access_token}`,
        contentType: "application/json;charset=utf-8",
      });
      console.log(blob);
      navigator.sendBeacon(
        `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
        blob
      );
    };
    // test();
    onMounted(() => {
      console.log(internal);
      internal = setInterval(() => {
        seconds++;
      }, 1000);
      window.addEventListener("unload", () => {
        test();
      });
    });
    onUnmounted(() => {
      window.removeEventListener("unload", test);
    });
    return { userInfo, handleAddclue };
  },
};
</script>

<style lang="scss" scoped></style>
