<template>
    <div>
    </div>
</template>

<script>
import {useRoute} from 'vue-router'
import {useWxAuthorizationSilence} from '@/hooks/wxAuthorization'
export default {
    setup () {
        const route = useRoute()
        useWxAuthorizationSilence(route.query.shareUrl)
        return {

        }
    }
}
</script>

<style lang="scss" scoped>

</style>