<template>
  <div>
    <h1>About</h1>
  </div>
</template>
<script>
import { useBrowseCount } from "@/hooks/browseCount";
import { useRoute } from "vue-router";
import { Dialog } from "vant";
export default {
  setup() {
    useBrowseCount({shareId: 1,clueSource:1});
    const route = useRoute();
    alert(route.query.appPhone)
    Dialog.alert({
      message: "22222===" + route.query.miniProgramPhone,
    });
    return {};
  },
};
</script>
<style lang="scss" scoped></style>
